import { admin2 } from '@/api/instance'

// => 取得電商參數
export const GetConfig = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceConfig`,
  })
  return res
}

// => 更新電商參數
export const UpdateEcommerceConfig = async ({
  shopId,
  logo,
  domain,
  adminNotifyEmail,
  enableOrderInvoice,
  enableOrderInvoiceB2B,
  enableOrderInvoiceLoveCode,
  enableOrderInvoiceCarrier,
  enableOrderInvoicePrint,
  enableOrderWallet,
  paymentDeadlineHour,
  customerServiceLink,
  socialLinks,
  floatingButton,
  enableOrderInvoiceApi,
  enableOrderInvoiceApiConfig,
  fbPixelCode,
  googleAnalyticsMeasurementId,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceConfig`,
    data: {
      logo,
      domain,
      adminNotifyEmail,
      enableOrderInvoice,
      enableOrderInvoiceB2B,
      enableOrderInvoiceLoveCode,
      enableOrderInvoiceCarrier,
      enableOrderInvoicePrint,
      enableOrderWallet,
      paymentDeadlineHour,
      customerServiceLink,
      socialLinks,
      floatingButton,
      enableOrderInvoiceApi,
      enableOrderInvoiceApiConfig,
      fbPixelCode,
      googleAnalyticsMeasurementId,
    },
  })
  return res
}
