import { admin2 } from '@/api/instance'

export const GetFooterSetting = async ({
  shopId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceFooterConfig`,
  })
  return res
}

export const UpdateFooterSetting = async ({
  shopId,
  aboutUs,
  contactUs,
  faq,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceFooterConfig`,
    data: {
      aboutUs,
      contactUs,
      faq,
    },
  })
  return res
}
